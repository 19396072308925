:root {

    --min_width: 250px;

    /* NavBar */

    --navbar_height: 50px; /* Also change navbar_height in Navbar.js and Portfolio.js */

    /* About */

    --role-icon-size: 48px;
    --about_top_margin: 30px;
    --about_bottom_margin: 30px;

    /* Headshot */

    --headshot_upper_margin: 36px;
    --headshot_lower_margin: 18px;
    --headshot_height: 120px;

    /* Footer */
    
    --footer_vertical_margin: 10px;
    --social_icon_size: 24px; /* Also change icon sizes in Footer.js and codeforces.svg */
    --social_icon_margin_top: 4px;
    --social_icon_margin_lr: 6px;

    /* Portfolio */

    --logo_width: 35px;

    /*------------------------- Cute -------------------------*/

    /* NavBar */

    --navbar_cute_width: 250px;

    --navbar_cute_side_margin: 40px;

    --small_pane_cute_width: 35%;

    --about_container_cute_width: 100%;

    /* Tags cloud */

    --tags_cloud_cute_width: 325px;

    /*------------------------ Middle ------------------------*/

    --max_single_pane_width: 720px;
}