@import '../../css/colors.css';
@import '../../css/font.css';

.project-section-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.project-section {
    margin: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: var(--bg_section);
    text-decoration: none;
    color: inherit;
    border-radius: 10px;
    backdrop-filter: blur(20px);
}

.project-section-cute {
    margin: 2px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bg_section);
    text-decoration: none;
    color: inherit;
    border-radius: 10px;
    backdrop-filter: blur(20px);
}

.hover-pointer:hover {
    cursor: pointer;
}

.thumbnail {
    width: 48%;
    margin-bottom: 10px;
    border-radius: 10px;
    /* border: 1px solid var(--text_primary); */
}

.thumbnail-cute {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    /* border: 1px solid var(--text_primary); */
}

.project-text {
    width: 50%;
    text-align: center;
}

.project-text-cute {
    width: 100%;
    text-align: center;
}

.title {
    width: 100%;
    text-align: center;
    font-size: var(--portfolio_title_left_fs);
    font-weight: var(--portfolio_title_left_fw);
    font-family: inherit;
    margin-bottom: 10px;
}
  
.project-section:hover {
    background-color: var(--bg_section_hover);
}

.content-container {
    width: 100%;
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
    margin: 0;
    text-align: justify;
    text-justify: inter-word;
}

.content-head {
    width: 100%;
    font-size: inherit;
    font-weight: inherit;
}

.content-list {
    list-style-type: disc;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    padding-left: 30px;
}

.content-item {
    width: 100%;
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
    font-family: var(--primary_ff);
    color: var(--text_primary);
}

.project-href {
    margin-left: 10px;
    color: var(--project_href_color);
    font-size: var(--project_href_fs);
    font-weight: var(--project_href_fw);
}

.project-href:hover {
    cursor: pointer;
}

.tags-container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.tags-container-left {
    justify-content: left;
    margin-left: 0%;
}

.tags-container-right {
    justify-content: right;
    margin-left: 20%;
}
