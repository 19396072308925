@import './colors.css';
@import './dimensions.css';
@import './font.css';

.footer {
  position: inherit;
  bottom: 0;
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--footer_vertical_margin);
  margin-top: var(--footer_vertical_margin);
}

.footer-cute {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--footer_vertical_margin);
  margin-top: var(--footer_vertical_margin);
}

.footer-row {
  margin: 0;
  text-align: center;
}

.email-text {
  font-family: var(--footer_email_ff);
  font-size: var(--footer_email_fs);
  font-weight: var(--footer_email_fw);
  color: var(--text_footer);
  text-decoration: underline;
}

.resume-text {
  font-family: var(--footer_resume_ff);
  font-size: var(--footer_resume_fs);
  font-weight: var(--footer_resume_fw);
  color: var(--text_footer);
  text-decoration: underline;
}

.footer-content {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.social-icon {
  color: var(--text_footer);
}

.icon-wrapper {
  height: var(--social_icon_size);
  width: var(--social_icon_size);
  justify-content: center;
  align-items: center;
  margin-top: var(--social_icon_margin_top);
  margin-left: var(--social_icon_margin_lr);
  margin-right: var(--social_icon_margin_lr);
}