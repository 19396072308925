@import './colors.css';
@import './dimensions.css';
@import './font.css';

.outer-wrapper {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  /* min-width: var(--min_width); */
  background: var(--bg_formula);
  background-attachment: fixed;
  font-family: var(--primary_ff);
}

.single-pane {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}

.add-margin-due-to-navbar {
  margin-top: var(--navbar_height);
}

.single-pane-restricted-width {
  max-width: var(--max_single_pane_width);
  margin-left: calc((100% - var(--max_single_pane_width)) / 2);
}

.double-pane {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: var(--navbar_height);
  font-family: inherit;
  color: var(--text_primary);
}

.big-pane {
  margin-left: 40%;
  margin-bottom: var(--margin_bottom_px);
  width: 45%;
  display: flex;
  flex-direction: column;
}

.small-pane {
  position: fixed;
  left: 10%;
  width: 20%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--text_navbar);
  color: var(--text_small_pane);
}

.small-pane-cute {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--text_navbar);
  color: var(--text_small_pane);
}

.small-pane-cute-header {
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.small-pane-cute-footer {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.headshot-container {
  margin-top: var(--headshot_upper_margin);
  margin-bottom: var(--headshot_lower_margin);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headshot-container-cute {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--footer_vertical_margin);
  margin-bottom: var(--footer_vertical_margin);
}

.headshot {
  height: var(--headshot_height);
  width: var(--headshot_height);
  border: 2px solid var(--text_primary);
  border-radius: 50%;
}

.name {
  margin-top: 10px;
  width: 100%;
  font-family: var(--name_ff);
  font-size: var(--name_fs);
  font-weight: var(--name_fw);
  text-align: center;
}

#navbar-cute-wrapper {
  display: none;
}

.expand-button {
  z-index: 1;
  display: flex;
  position: fixed;
  height: 45px;
  width: 45px;
  background-color: var(--bg_navbar);
  border-radius: 5px;
  border: 0;
  color: var(--text_navbar);
  align-items: center;
  justify-content: center;

}

#expand-navbar-button {
  top: 10px;
  right: 20px;
}

#expand-tags-cloud-button {
  top: 10px;
  left: 20px;
}

.expand-button:hover {
  cursor: pointer;
}

#tags-cloud-cute-wrapper {
  display: none;
  z-index: 1;
  width: var(--tags_cloud_cute_width);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg_navbar);
  backdrop-filter: blur(2px);
  color: var(--text_navbar);
}