@import '../../css/font.css';
@import '../../css/colors.css';
@import '../../css/dimensions.css';


.tags-cloud {
    width: 100%;
    top: var(--navbar_height);
    display: flex;
    flex-direction: column;
    font-size: var(--tags_fs);
    font-weight: var(--tags_fw);
    overflow-y: scroll;
}

.tags-cloud-full-height {
    height: calc(100vh - var(--navbar_height));
}

.tags-cloud-height-constraint {
    /* 250px is the total height w/o margins of headshot container and footer */
    max-height: calc(100% - 250px - var(--navbar_height) - var(--headshot_upper_margin) - var(--headshot_lower_margin) - 2 * var(--footer_vertical_margin));
}

.tags-cloud-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-family: inherit;
    font-size: var(--tags_cloud_heading_fs);
    font-weight: var(--tags_cloud_heading_fw);
}

.tags-cloud-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skill-category-container {
    width: 96%;
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
}

.skill-category-title {
    width: 100%;
    margin-bottom: 2px;
    text-align: center;
    text-decoration: underline;
    font-size: var(--tags_category_fs);
    font-weight: var(--tags_category_fw);
}

.skill-category-tags {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.tags-cloud-cute-margin {
    margin-top: 40px;
}

.clickable:hover {
    cursor: pointer;
}
