@import '../../css/colors.css';
@import '../../css/dimensions.css';
@import '../../css/font.css';

.about-container {
    width: 100%;
    /* margin-top: var(--about_top_margin); */
    margin-bottom: var(--about_bottom_margin);
}

.about-container-cute {
    width: var(--about_container_cute_width);
    /* margin-top: var(--about_top_margin); */
    margin-bottom: var(--about_bottom_margin);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.roles-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.roles-container-vertical {
    width: 92%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.role {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.role-full {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.role-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.role-title {
    width: 100%;
    height: var(--role-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--role_title_fs);
    font-weight: var(--role_title_fw);
    font-family: inherit;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-right: 3px solid;
    border-top: 3px solid;
    border-bottom: 3px solid;
    border-radius: 2px;
    /* border-left: 0; */
}

.role-content {
    width: 94%;
    text-align: justify;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: var(--primary_ff);
    color: var(--text_primary);
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
}

#swe-role {
    color: var(--text-react);
    border-color: var(--border-react);
}

#ml-role {
    color: var(--text-pytorch);
    border-color: var(--border-pytorch);
}
