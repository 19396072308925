:root {

    /* NavBar */

    --bg_navbar: rgba(0, 0, 0, 0.85);
    --text_navbar: var(--bg_small_pane);
    --text_navbar-dead: #6d726b;

    /* Main background */

    --bg_formula: #fefbe9;
    --text_primary: rgb(67, 67, 67);

    /* Small pane */

    --bg_small_pane: #e1eedd;
    --text_small_pane: #183a1d;

    /* Footer */

    --text_footer: var(--text_small_pane);

    /* About section */

    --text-pytorch: #ee4c2c;
    --border-pytorch: #323232;

    --text-react: #61dafb;
    --border-react: var(--border-pytorch);

    /* Portfolio */

    --text_clear_element: #ee4c2c;
    --bg_clear_element: #eba99c76;
    /* --text_clear_element: var(--text_small_pane);
    --bg_clear_element: var(--bg_small_pane); */

    --bg_section: transparent;
    --bg_section_hover: var(--bg_section);
    --bg_highlight: #e1eedd;
    --project_href_color: #ee4c2c;

    /* Tags */

    --bg_tags: transparent;
    --bg_tags_hover: rgba(0, 0, 0, 0.2);
    --bg_tags_sel: var(--bg_tags_hover);
    --text_tags_unsel: black;
    --text_tags_sel: var(--text_tags_unsel);
    --text_section_tags: var(--text_tags_unsel);

    /*------------------------- Cute -------------------------*/

    /* Tags */
    --bg_tags_cute_hover: #e1eedd52;
    --bg_tags_cute_sel: var(--bg_tags_cute_hover);
}