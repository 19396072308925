@import '../../css/colors.css';
@import '../../css/font.css';
@import '../../css/dimensions.css';

.portfolio-section {
    margin: 2px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: var(--bg_section);
    backdrop-filter: blur(20px);
}

.hover-pointer:hover {
    cursor: pointer;
}

.visible-section {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.logo-container {
    margin: 0;
}

.logo {
    width: var(--logo_width);
    margin: 5px;
    border-radius: 6px;
}

.visible-text {
    width: calc(100% - 50px);
    margin-left: 5px;
    margin-left: 5px;
}

.title-container {
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title-left {
    font-size: var(--portfolio_title_left_fs);
    font-weight: var(--portfolio_title_left_fw);
    font-family: inherit;
    text-align: left;
}

.title-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    font-size: var(--portfolio_title_right_fs);
    font-weight: var(--portfolio_title_right_fw);
    font-family: inherit;
}

.subtitle-list {
    width: 100%;
}

.subtitle-container {
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subtitle-container-cute {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.designation {
    position: relative;
    left: 0;
    font-size: var(--portfolio_subtitle_left_fs);
    font-weight: var(--portfolio_subtitle_left_fw);
    font-family: inherit;
}

.date {
    position: relative;
    display: flex; 
    flex-direction: row;
    right: 0;
    text-align: right;
    font-size: var(--portfolio_subtitle_right_fs);
    font-weight: var(--portfolio_subtitle_right_fw);
    font-family: inherit;
}

.period-delimiter {
    margin-left: 2px;
    margin-right: 2px; 
}

.elaborate {
    background-color: inherit;
    border: 0;
}

.elaborate:hover {
    cursor: pointer;
}

.content-container {
    width: 100%;
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
    margin: 0;
    text-align: justify;
    text-justify: inter-word;
}

.content-head {
    width: 100%;
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
    font-family: var(--primary_ff);
    color: var(--text_primary);
}

.content-list {
    list-style-type: disc;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    padding-left: 30px;
}

.content-item {
    width: 100%;
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
    font-family: var(--primary_ff);
    color: var(--text_primary);
}

.tags-container {
    margin-top: 5px;
    margin-left: 20%;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: right;
}

.portfolio-section:hover {
    background-color: var(--bg_section_hover);
}
