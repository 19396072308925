:root {

    --primary_ff: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    /* About Section */
    
    --expertise_fs: 24px;
    --expertise_fw: 400;
    /* --expertise_ff: Cervo-Light, serif; */
    --expertise_ff: "Exo 2", sans-serif;

    --role_title_fs: 128%;
    --role_title_fw: 400;

    --primary_fs: 14px;
    --primary_fw: 400;

    /* Nav Bar */
    
    --navbar_fs: 13.5px;
    --navbar_fw: 600;

    /* Name */

    --name_fs: 24px;
    --name_fw: 400;
    --name_ff: var(--expertise_ff);
    
    /* Tags cloud */

    --tags_cloud_heading_fs: 18px;
    --tags_cloud_heading_fw: 500;

    --tags_category_fs: 14px;
    --tags_category_fw: 500;
    
    --tags_fs: 11px;
    --tags_fw: 400;
    
    --tags_clear_fs: 12px;
    --tags_clear_fw: 500;

    /* Footer */

    --footer_email_ff: Arial, sans-serif;
    --footer_email_fs: 18px;
    --footer_email_fw: 900;

    --footer_resume_ff: var(--footer_email_ff);
    --footer_resume_fs: var(--footer_email_fs);
    --footer_resume_fw: var(--footer_email_fw);

    /* Portfolio section */

    --portfolio_title_left_fs: 16px;
    --portfolio_title_left_fw: 700;

    --portfolio_title_right_fs: 13px;
    --portfolio_title_right_fw: 500;

    --portfolio_subtitle_left_fs: 14px;
    --portfolio_subtitle_left_fw: 500;

    --portfolio_subtitle_right_fs: 11px;
    --portfolio_subtitle_right_fw: 400;

    --project_href_fs: 18px;
    --project_href_fw: var(--primary_fw);
        
}