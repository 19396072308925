@import '../../css/colors.css';

.portfolio-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    color: var(--text_primary);
}

.section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px;
}

.highlight {
    background-color: var(--bg_highlight);
}

.filtering-by-skills {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: inherit;
    font-size: var(--tags_cloud_heading_fs);
    font-weight: var(--tags_cloud_heading_fw);
    margin-top: 20px;
    margin-bottom: 20px;
}

.clear-skills-filter {
    width: 100%;
    height: 36px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    background-color: var(--bg_clear_element);
    color: var(--text_clear_element);
    border-radius: 5px;
    border: 1px solid var(--text_clear_element);
    font-family: var(--primary_ff);
    font-size: var(--primary_fs);
    font-weight: var(--primary_fw);
}

.clear-skills-filter:hover {
    cursor: pointer;
}