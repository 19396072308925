@import './colors.css';
@import './dimensions.css';
@import './font.css';

.navbar {
    z-index: 1;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    height: var(--navbar_height);
    width: 100%;
    background-color: var(--bg_navbar);
    backdrop-filter: blur(2px);
}

.navbar-cute {
    z-index: 1;
    width: var(--navbar_cute_width);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--bg_navbar);
    backdrop-filter: blur(2px);
}

.nav-menu {
    height: 100%;
    margin-top: 0;
    margin-left: 12%;
    margin-right: 12%;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.nav-menu-cute {
    margin-top: var(--about_top_margin);
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.nav-item-font {
    font-size: var(--navbar_fs);
    font-weight: var(--navbar_fw);
    text-align: center;
    text-decoration: none;
}

.nav-item-dim {
    margin-left: 24px;
    margin-right: 24px;
}

.nav-item-cute-dim {
    height: 40px;
    width: 100%;
}

.nav-external {
    margin-left: 2px;
}

.blank-space {
    width: var(--navbar_cute_side_margin);
}

.nav-link-common {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: inherit;
}

.nav-link {
    color: var(--text_navbar);
}

.nav-link-dead {
    color: var(--text_navbar-dead);
}

.nav-link:hover {
    cursor: pointer;
}

.icon-small {
    font-size: 12px; /* Adjust the font size as per your preference */
}
